import React from "react";
import {Link} from "react-router-dom";
import {StorageContext} from "./StorageContext";

function PageNotFound(){
    document.title = "Page Not Found"

    const {setCurrentPage} = React.useContext(StorageContext)

    React.useEffect(()=>{
        setCurrentPage("404")
    },[setCurrentPage])

    React.useEffect(()=>{
        document.body.style.paddingTop = 0

        let eyes = document.getElementById("eyes")
        let width = window.screen.width
        let height = window.screen.height
        let mouseX = height/2
        let mouseY = width/2

        document.onmousemove = (event)=>{
            //verticalAxis
            mouseY = event.pageY;
            let yAxis = (height / 2 - mouseY) / height * 300;
            //horizontalAxis
            mouseX = event.pageX / -width;
            let xAxis = -mouseX * 100 - 100;
            eyes.style.transform = 'translate(' + xAxis + '%,-' + yAxis + '%)'
        }
    },[])

    return(
        <React.Fragment>
            <section>
                <div className="banner_cover d-flex align-items-center" style={{paddingTop:"0"}}>
                <div className="container">
                    <div className="banner_content text-center">
                        <div className="box">
                            <h2 className="box__title">Error 404</h2>
                            <h5 className="box__title">Page Not Found</h5>
                            <div className="box__ghost">
                                <div className="symbol"></div>
                                <div className="symbol"></div>
                                <div className="symbol"></div>
                                <div className="symbol"></div>
                                <div className="symbol"></div>
                                <div className="symbol"></div>
                                <div className="box__ghost-container">
                                    <div className="box__ghost-eyes" id="eyes">
                                        <div className="box__eye-left"></div>
                                        <div className="box__eye-right"></div>
                                    </div>
                                    <div className="box__ghost-bottom">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="box__ghost-shadow"></div>
                            </div>
                            <div className="box__description">
                                <div className="box__description-container">
                                    <div className="box__description-title">Whoops!</div>
                                    <div className="box__description-text" style={{color: "#fff"}}>
                                        It seems like we couldn&#x27;t find the page you were looking for.<br/>You
                                        can always try reloding.
                                    </div>
                                </div>
                                <Link className="box__button" to="/">Go to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default PageNotFound