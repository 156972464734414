import React from "react";
import axios from "axios";
import {StorageContext} from "./StorageContext";
import {Link} from "react-router-dom";
import logo from '../images/logo.png'
import Configs from "./Configs";

function Home(){
    document.title = "Pixienes"

    const {setCurrentPage} = React.useContext(StorageContext)

    const [visits, setVisits] = React.useState(0)
    const [pixienesList, setPixienesList] = React.useState([])


    React.useEffect(()=>{
        setCurrentPage('home')
    },[setCurrentPage])

    React.useEffect(()=>{
        axios.get(`https://api.countapi.xyz/hit/${Configs.NAMESPACE}/${Configs.COUNT_API_KEY}`)
            .then(response=> setVisits(response.data.value))
            .catch(error=>console.log(error))
    },[])

    React.useEffect(()=>{
        axios.get("https://0xdigitalart.heptadecane.xyz/apis/pixienes.json")
            .then(response => setPixienesList(response.data))
            .catch(error=>console.log(error))
    },[])


    return(
        <React.Fragment>

            <div className="container">

                <div className="grid-container">
                    {pixienesList.map((element,index)=> <a href={element.openSeaLink} className="image_link" target="_blank" rel="noreferrer">
                            <div>
                                <img className='grid-item grid-item-1' src={element.preview} alt=''/>
                                <p>"{element.caption}"</p>
                            </div>
                        </a>
                    )}
                </div>

                <hr className="separator"/>

                <footer className="footer_area">
                    <div className="footer_logo">
                        <Link to="/">
                            <img src={logo} height="20px" alt=""/>
                        </Link>
                    </div>

                    <div>
                        <button className="count btn btn-outline-primary" disabled={true}>
                            Visits: {visits}
                        </button>
                    </div>
                    <p className="footer-text">
                        Copyright ©{new Date().getFullYear()} All rights reserved by <span className="blank_link">Heptadecane</span>
                    </p>
                </footer>
            </div>
        </React.Fragment>
    )
}
export default Home